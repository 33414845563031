import { 
	createStyles, 
	Grid, 
	Theme, 
	Typography, 
	withStyles, 
	WithStyles,
	Button, 
	Hidden,
	IconButton,
} from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import React, { Component } from 'react';
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import NavBarDrawer from './NavBarDrawer';
const logo = "https://wa-tools-plugin.s3.ap-south-1.amazonaws.com/full_logo.png"

class NavBarMobile extends Component<Props, cState> {
  static defaultProps = {
    // name: 'AiSensy',
  };
  state = {
    drawerOpen: false
  };
  onDrawerOpen = () => {
    this.setState({ drawerOpen: true });
  };
  onDrawerClose = () => {
    this.setState({ drawerOpen: false });
  };
  render(): React.ReactElement {
    const { classes } = this.props;
    const path = this.props.location.pathname;
    return (
      <Grid container alignItems="center" style={{height: 60}}>
        <Grid item>
          <IconButton onClick={this.onDrawerOpen}>
            <Menu />
          </IconButton>
        </Grid>
        <Grid item xs>
          <a 
            href="https://aisensy.com" 
            style={{display: "block", height: "34px"}}
            target="_blank"
            rel="noreferrer"
          >
            <img src={logo} className={classes.logo} />
          </a>
        </Grid>
        {/* <Grid item>
          <Button 
            color="primary"
            style={{borderRadius: 25}}
            component={Link}
            to="/generate-whatsapp-link"
          >
            WhatsApp Link
          </Button>	
        </Grid> */}
        <Grid item>
          <ActiveBtn path={path} />	
        </Grid>
        <NavBarDrawer drawerOpen={this.state.drawerOpen} onDrawerClose={this.onDrawerClose}/>
      </Grid>
    )
  }
  


}

const ActiveBtn = (props: { path: string }): React.ReactElement => {
  switch (props.path) {
    case "/generate-whatsapp-link":
      return (
        <Button 
          color="primary"
          style={{borderRadius: 25, marginRight: 20}}
          component={Link}
          to="/generate-whatsapp-button"
          variant="outlined"
          size="small"
        >
          WhatsApp Button
        </Button>	
      );
      case "/generate-whatsapp-button":
        return (
          <Button 
            color="primary"
            style={{borderRadius: 25, marginRight: 20}}
            component={Link}
            to="/generate-whatsapp-link"
            variant="outlined"
            size="small"
          >
            WhatsApp Button
          </Button>	
        );
    default: 
      return (
        <Button 
          color="primary"
          variant="outlined"
          style={{borderRadius: 25, marginRight: 20}}
          size="small"
          href="https://app.aisensy.com/signup"
          target="_blank"
        >
          Sign Up
        </Button>
      );
  }

}

interface Props extends WithStyles<typeof styles>, RouteComponentProps{
	// name: string,
	// number: string
}

interface cState {
  drawerOpen: boolean
}

const styles = (theme: Theme) => createStyles({
	"root": {
		height: 60,
		width: "100vw",
		position: "sticky",
		top: 0,
		left: 0,
		// backgroundColor: "#EBF5F3",
		backgroundColor: "#ffffff",
		boxShadow: "rgb(0 0 0 /10%) 0px 0px 0.428571rem 0px",
		// backgroundColor: theme.palette.primary.main,
		// paddingLeft: theme.spacing(2),
		boxSizing: "border-box",
		zIndex: 100,
		// color: "white"
		// boxShadow: "0px 0px 2px 2px lightgrey"
	},
	"logo": {
		height: 50,
		marginRight: 50,
		[theme.breakpoints.down('md')]: {
			marginRight: 20,
			// width: 90,
			marginLeft: 10,
      height: 34
		}
	}
})

export default withStyles(styles)(withRouter(NavBarMobile));