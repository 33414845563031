import React, { Component } from 'react';
import {
  withStyles,
  Box,
  Grid,
  WithStyles,
  Theme,
  createStyles,
  Typography,
  Button,
} from '@material-ui/core';
// import QRCode from "qrcode.react";
// const WhatsAppImage = "https://create.wa.link/static/WhatsApp-0e878a0fa68c61b06e781cee2e6bc71f.svg"
// const AiSensyLogo = "http://aisensy-superadmin.s3-website.ap-south-1.amazonaws.com/favicon.ico";

const snippetScript = (linkId: string) => {
  return `
    <script 
      type="text/javascript"
      src="https://d3mkw6s8thqya7.cloudfront.net/integration-plugin.js"
      id="aisensy-wa-widget"
      widget-id="${linkId}"
    >
    </script>
	`;
};

class LinkDialogContent extends Component<Props, cState> {
  static defaultProps = {
    name: 'AiSensy',
    linkid: 'default value',
  };
  state = {
    countryCode: '',
    name: this.props.name,
    copied: false,
  };
  // async componentDidMount(): Promise<void> {}
  downloadQR = () => {
    const canvas = document.getElementById(
      'react-qrcode-logo',
    ) as HTMLCanvasElement;
    if (canvas) {
      const pngUrl = canvas
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream');
      const downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = this.props.linkid + '.png';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };
  clickToCopy = () => {
    if(this.props.isSrcInstamojo) {
      navigator.clipboard.writeText(this.props.linkid);
    } else {
      const script = snippetScript(this.props.linkid);
      navigator.clipboard.writeText(script);
    }
    // Pop up alert that link has been copied to clipboard
    this.setState({ copied: true });
  };
  render(): React.ReactElement {
    const { classes, isSrcInstamojo } = this.props;
    if (!isSrcInstamojo) {
      return (
        <div className={classes.root}>
          <Typography variant="h3" style={{ color: 'black' }} paragraph>
            Here is your Widget Snippet
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
          {"Copy and paste this snippet within the <head> tag of your webpage."}
          </Typography>
          <Box pt={2} mb={3}>
            <div className={classes.snippetContainer}>
              <pre style={{ tabSize: 2 }}>{snippetScript(this.props.linkid)}</pre>
            </div>
          </Box>
          {this.state.copied ? (
            <Typography
              style={{ color: 'green' }}
              variant="body1"
              paragraph
              align="center"
            >
              Snippet has been copied to clipboard !!
            </Typography>
          ) : (
            <Typography
              color="textSecondary"
              variant="body1"
              paragraph
              align="center"
            >
              FREE !!- Track clicks & much more,{' '}
              <a href="https://aisensy.com" target="_blank" rel="noreferrer">
                Sign Up
              </a>{' '}
              now
            </Typography>
          )}
          <Grid container justify="center" spacing={3}>
            <Grid item>
              {!this.state.copied ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.clickToCopy}
                  style={{color: "white"}}
                >
                  Copy Snippet
                </Button>
              ) : (
                <Button onClick={this.props.closeDialog}>Go Back</Button>
              )}
            </Grid>
          </Grid>
          {/* <Box py={2}>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography color="primary" variant="h4">
                  Access premium features for FREE
                </Typography>
              </Grid>
              <Grid item>
                <Button color="primary"> Sign Up</Button>
              </Grid>
            </Grid>
          </Box> */}
        </div>
      );
    } else {
      return (
        <div className={classes.root}>
          <Typography variant="body1" color="primary" paragraph align="center">
            Widget generated successfully!
          </Typography>
          <Typography variant="h3" paragraph align="center" style={{fontWeight: "bold"}}>
            {this.props.linkid}
          </Typography>
          <Typography variant="body2" color="textSecondary" paragraph align="center">
            {"Copy this widget-id -> Click on Configure button on top-right -> Paste widget-id -> Save & done!"}
          </Typography>
          {this.state.copied ? 
            (
              <Typography
                style={{ color: 'green' }}
                variant="body1"
                paragraph
                align="center"
              >
                Copied!
              </Typography>
              ) 
            : ""
          }
          <Grid container justify="center" spacing={3}>
            <Grid item>
              {!this.state.copied ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.clickToCopy}
                  style={{color: "white"}}
                >
                  Copy Widget Id
                </Button>
              ) : (
                <Button onClick={this.props.closeDialog}>Go Back</Button>
              )}
            </Grid>
          </Grid>
          {/* <Box py={2}>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography color="primary" variant="h4">
                  Access premium features for FREE
                </Typography>
              </Grid>
              <Grid item>
                <Button color="primary"> Sign Up</Button>
              </Grid>
            </Grid>
          </Box> */}
        </div>
      );
      
    }
  }
}

interface Props extends WithStyles<typeof styles> {
  name: string;
  linkid: string;
  closeDialog: () => void;
  isSrcInstamojo: boolean;
  // number: string
}

interface cState {
  countryCode: string;
  name: string;
  copied: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      minHeight: 200,
      backgroundColor: 'white',
      boxSizing: 'border-box',
      marginBottom: 10,
      padding: '20px',

    },
    whatsappimg: {
      height: 50,
      marginRight: 15,
    },
    snippetContainer: {
      height: 200,
      width: '100%',
      overflow: 'auto',
      background: 'rgb(245, 245, 245)',
      boxSizing: 'border-box',
      padding: '0px 25px',
      borderRadius: '8px',
    },
  });

export default withStyles(styles)(LinkDialogContent);
