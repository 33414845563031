import { 
	createStyles, 
	Grid, 
	Theme, 
	Typography, 
	withStyles, 
	WithStyles,
	Button, 
	Hidden,
	IconButton,
} from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import React from 'react';
import { Link } from "react-router-dom";
import NavBarDesktop from './NavBarDesktop';
import NavBarMobile from './NavBarMobile';
const logo = "https://2es93q3aicyy3w1qrv1vk3o2-wpengine.netdna-ssl.com/wp-content/uploads/2021/02/Untitled.png"

const Navbar = (props: Props): React.ReactElement  => {
	const { classes } = props
	return (
		<div className={classes.root}>
			<Grid container justify="center">
				<Grid item xs={12} md={10} lg={7}>
					{/* Put all content in here */}
					<Hidden smUp>
						<NavBarMobile />
					</Hidden>
					<Hidden xsDown>
						<NavBarDesktop />
					</Hidden>
				</Grid>
			</Grid>
		</div>
	)
}

interface Props extends WithStyles<typeof styles> {
	// name: string,
	// number: string
}

const styles = (theme: Theme) => createStyles({
	"root": {
		height: 60,
		width: "100vw",
		position: "sticky",
		top: 0,
		left: 0,
		// backgroundColor: "#EBF5F3",
		backgroundColor: "#ffffff",
		boxShadow: "rgb(0 0 0 /10%) 0px 0px 0.428571rem 0px",
		// backgroundColor: theme.palette.primary.main,
		// paddingLeft: theme.spacing(2),
		boxSizing: "border-box",
		zIndex: 100,
		// color: "white"
		// boxShadow: "0px 0px 2px 2px lightgrey"
	},
	"logo": {
		height: 50,
		marginRight: 50,
		[theme.breakpoints.down('md')]: {
			marginRight: 20,
			width: 60,
			marginLeft: 10
		}
	}
})

export default withStyles(styles)(Navbar);