import React from 'react';
import { cState } from "../pages/WhatsAppBtnPage/ButtonGenerator";
import { variantType, widgetSessionWindowType } from '../types/link.type';
export const getInstamojoConfig = (storeDomain: string) => {
  const config: cState = {
    env: 'dev',
    isSubmitting: false,
    errmsg: '',
    linkid: '',
    phoneNumber: '',
    variant: variantType.btnWithWidget,
    btnBackground: '#4dc247',
    btnCTA: 'Chat with us',
    mb: '30',
    ml: '30',
    mr: '30',
    borderRadius: '24',
    prefilledMsg: 'Hi',
    position: 'Bottom-Right',
    brandName: 'AiSensy',
    brandSub: 'online',
    brandColor: '#0A5F54',
    brandImgUrl: 'https://aisensy-project-media-library.s3.ap-south-1.amazonaws.com/IMAGE/606c10aa3ab7b800170522c3/4045404_emoticon.png',
    widgetBtnCTA: 'Start chat',
    openWidgetByDefault: 'true',
    openWidgetSessionWindow: widgetSessionWindowType.hour24,
    onscreenMsg: "Hi,\nHow can I help you ?",
    widgetOnMobile: 'false',
    urlPersonalization: true,
    personalizedUrls: [
      {
        url: `https://${storeDomain}/product/{{product_id}}/{{product_name}}`,
        userMsg:  `Hi, I'm interested in {{product_name}}`,
        urlMsg: "Hey there! \nWould you like to know about this product?!",
        key: 0,
        toFormat: true,
        toCapitalize: true,
        prefilledRef: React.createRef<HTMLTextAreaElement>(),
        onscreenRef: React.createRef<HTMLTextAreaElement>()
      },
    ],
  }
  return config;
}