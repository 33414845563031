import React, { Component } from 'react';
import { 
	withStyles, 
	Box, 
	Grid, 
	WithStyles, 
	Theme, 
	createStyles, 
	Typography,
	TextField,
	Button,
} from '@material-ui/core';
import { AccountCircle, Send } from '@material-ui/icons';

class MobileMock extends Component<Props, cState> {
	static defaultProps = {
		name: "AiSensy",
	}
	state = {
		countryCode: "",
		name: this.props.name
	}
	async componentDidMount(): Promise<void> {
		const clientId = ""
	}
	render(): React.ReactElement {
    const { classes } = this.props
		return (
			<div className={classes.root}>
        <div className={classes.screen}>
          <div className={classes.windowBar}>
            <Grid container alignItems='center' style={{height: "100%"}}>
              <Grid item>
                <AccountCircle className={classes.personIcon} />
              </Grid>
              <Grid item>
                <Typography variant="h4" style={{color: "rgb(0,0,0,0.7)"}}>
                  {this.props.countryCode} {this.props.phoneNumber}
                </Typography>
              </Grid>

            </Grid>
          </div>
          {
            !this.props.previewMsg && this.props.msg?
              <div className={classes.msgContainer}>
                <Typography variant="body2">
                  {this.props.msg}
                </Typography>
              </div>
            : ""
          }
          <div className={classes.windowInputBar}>
            <Grid container alignItems='center' style={{height: "100%"}}>
              <Grid item xs>
                <TextField 
                  type="text"
                  placeholder="Type a message"
                  className={classes.textInput}
                  value={ this.props.previewMsg ? this.props.msg : ""}                
                />
              </Grid>
              <Grid item>
                <Send style={{marginRight: "8px"}} />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    )
  }
}


interface Props extends WithStyles<typeof styles> {
	name: string,
  previewMsg: boolean,
  msg: string,
  phoneNumber: string,
  countryCode: string
	// number: string
}

interface cState {
	countryCode: string,
	name: string
}

const styles = (theme: Theme) => createStyles({
	"root": {
		width: "100%",
    maxWidth: 280,
    height: 500,
		backgroundColor: "white",
		boxSizing: "border-box",
		borderRadius: 8,
    border: "1px solid rgb(0, 0, 0, 0.2)",
    marginBottom: 10,
    padding: "12px 10px 16px 10px",
	},
  "screen": {
    width: "100%",
    background: "#ece5dd",
    height: "100%",
    position: "relative",
    overflowX: "hidden",
    overflowY: "auto"
    // borderRadius: 8
  },
  "windowBar": {
    height: 45,
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: "#f0f1f2",
  },
  "windowInputBar": {
    height: 45,
    width: "100%",
    position: "absolute",
    bottom: 0,
    left: 0,
    backgroundColor: "#f0f1f2",
  },
  "personIcon": {
    fontSize: 30,
    color: "#afafaf",
    margin: "0 10px"
  },
  "textInput": {
    backgroundColor: "white",
    margin: "0 8px",
    borderRadius: "20px",
    height: 35,
    paddingTop: 4
  },
  "msgContainer": {
    borderRadius: 4,
    padding: 8,
    paddingLeft: "10px",
    position: "absolute",
    bottom: 50,
    right: 8,
    width: "70%",
    backgroundColor: "#DCF8C6",
    transition: "1s"
  }
})

export default withStyles(styles)(MobileMock);