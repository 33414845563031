import { 
	createStyles, 
	Grid, 
	Theme, 
	Typography, 
	withStyles, 
	WithStyles,
	Button, 
  Drawer,
  Box,
  ButtonBase
} from '@material-ui/core';
import {  ToysOutlined, BookOutlined, ForumOutlined, HomeOutlined, LinkOutlined, ModeCommentOutlined, WhatsApp, AttachMoney } from '@material-ui/icons';
import React from 'react';
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

const logo = "https://wa-tools-plugin.s3.ap-south-1.amazonaws.com/full_logo.png"

const topRoutes = [
  {
    name: "Home",
    to: "/",
    icon: <HomeOutlined style={{ fontSize: "20px" }}/>,
    ext: false
  },
  {
    name: "WhatsApp Link",
    to: "/generate-whatsapp-link",
    icon: <LinkOutlined style={{ fontSize: "20px" }}/>,
    ext: false
  },
  {
    name: "WhatsApp Button",
    to: "/generate-whatsapp-button",
    icon: <ModeCommentOutlined style={{ fontSize: "20px" }}/>,
    ext: false
  },
  {
    name: "Pricing",
    to: "https://m.aisensy.com/pricing/",
    icon: <AttachMoney style={{ fontSize: "20px" }}/>,
    ext: true
  },
  {
    name: "Platform",
    to: "https://aisensy.com",
    icon: <WhatsApp style={{ fontSize: "20px" }}/>,
    ext: true
  },
  {
    name: "Resources",
    to: "https://help.aisensy.com",
    icon: <BookOutlined style={{ fontSize: "20px" }}/>,
    ext: true
  },
  {
    name: "Integrations",
    to: "https://aisensy.com/integrations",
    icon: <ToysOutlined style={{ fontSize: "20px" }}/>,
    ext: true
  },
]


const NavbarDrawer = (props: Props): React.ReactElement  => {
	const { classes, drawerOpen, onDrawerClose, location } = props;
  const openRoute = (route: typeof topRoutes[0]) => {
    if (!route.ext) {
      props.history.push(route.to);
    } else {
      window.open(route.to, "_blank");
    }
    onDrawerClose();
  };
	return (
    <Drawer
      anchor="left"
      open={drawerOpen}
      onClose={onDrawerClose}
      classes={{
        paper: classes.drawerPaper,
      }}
      style={{ zIndex: 10010 }}
    >
      <Box px={2} className={classes.container}>
        <Grid container alignItems="center" style={{ margin: "15px 0px" }}>
          <Grid item xs>
            <a 
              href="https://aisensy.com" 
              style={{ height: "24px"}}
              target="_blank"
              rel="noreferrer"
            >
              <img src={logo} className={classes.logo} />
            </a>
          </Grid>
          <Grid item>
            <Button 
              variant="outlined"
              color="primary"
              style={{borderRadius: 25}}
              href="https://app.aisensy.com/signup"
              target="_blank"
              size="small"
            >
              Sign Up
            </Button>	
          </Grid>
        </Grid>
        <Typography variant="body2" color="textSecondary">
          #1 Whatsapp Marketing, CRM & Chatbot Platform
        </Typography>
        <Box py={2} />
        {topRoutes.map((route, index) => {
          let className = "inactive";
          if (location.pathname === route.to) {
            className = "active";
          }

          return (
            <ButtonBase              
              key={index}
              className={`${classes.linkButton} ${classes[className as keyof typeof classes]}`}
              onClick={() => openRoute(route)}
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes["icon_" + `${className}` as keyof typeof classes]}
                  >
                    {route.icon}
                  </Box>
                </Grid>
                <Grid item xs>
                  <Typography
                    variant="body2"
                    align="left"
                    style={{ fontSize: 14 }}
                    className={classes["name_" + `${className}` as keyof typeof classes]}
                  >
                    {route.name}
                  </Typography>
                </Grid>
              </Grid>
            </ButtonBase>
          )
        })}
        <Box my={20} />
      </Box>
    </Drawer>		
	)
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
	// name: string,
	// number: string
  drawerOpen: boolean,
  onDrawerClose: () => void
}

const styles = (theme: Theme) => createStyles({
  "logo": {
		height: 30,
		marginRight: 10,
	},
  container: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
    boxSizing: "border-box",
    overflowX: "hidden",
    // minWidth: "300px",
    // flexGrow: 1,
  },
  drawerPaper: {
    width: "calc(100vw - 70px)",
    height: "100%",
    position: "relative",
    overflow: "hidden",
    zIndex: 5000,
  },
  avatarRoot: {
    backgroundColor: "rgb(245,245,245)",
    color: theme.palette.primary.main,
    margin: "0px 10px",
  },
  link_inactive: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "35px",
    width: "35px",
    margin: "auto",
    borderRadius: "50%",
    cursor: "pointer",
    transition: "0.5s",
  },
  link_active: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "35px",
    width: "35px",
    margin: "auto",
    borderRadius: "50%",
    backgroundColor: "white",
    color: "white",
    cursor: "pointer",
    transition: "0.5s",
  },
  // profile_active: {

  // },
  // profile_inactive: {

  // }
  icon_active: {
    color: theme.palette.primary.main,
  },
  icon_inactive: {
    color: "rgb(20,20,20)",
  },
  name_active: {
    color: theme.palette.primary.main,
  },
  name_inactive: {
    color: "rgb(20,20,20)",
  },
  linkButton: {
    margin: "3px 0em",
    width: "100%",
    borderRadius: "5px",
    padding: "10px",
  },
  active: {
    backgroundColor: "#EBF5F3",
  },
  inactive: {
    backgroundColor: "transparent",
  },
  fullWidth: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
  },
  profileWrapper: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    backgroundColor: "white",
    paddingTop: theme.spacing(2) * 1.5,
    paddingBottom: theme.spacing(2) * 1.5,
  },
  profileIcon: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
    color: "white",
    width: "40px",
    height: "40px",
    margin: "0px 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
})

export default withStyles(styles)(withRouter(NavbarDrawer));