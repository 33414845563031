import { 
	createStyles, 
	Grid, 
	Theme, 
	Typography, 
	withStyles, 
	WithStyles,
	Button, 
	Hidden,
	IconButton,
  Link as HREFLink
} from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import React from 'react';
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
const logo = "https://wa-tools-plugin.s3.ap-south-1.amazonaws.com/full_logo.png"

const NavbarDesktop = (props: Props): React.ReactElement  => {
	const { classes } = props;
  const path = props.location.pathname;
	return (
    <Grid container alignItems="center" style={{height: 60}}>
      <Grid item>
        <HREFLink href="https://aisensy.com" target="_blank">
          <img src={logo} className={classes.logo} />
        </HREFLink>
      </Grid>
      <Grid item>
        <Button 
          style={{borderRadius: 25, marginRight: 20, color: "black", fontFamily: "Poppins" }}
          href="https://m.aisensy.com/pricing"
          target="_blank"
        >
          Pricing
        </Button>	
      </Grid>
      <Grid item>
        <Button 
          style={{borderRadius: 25, marginRight: 20, color: "black", fontFamily: "Poppins" }}
          href="https://aisensy.com/"
          target="_blank"
        >
          Platform
        </Button>	
      </Grid>
      <Grid item>
        <ActiveBtn path={path} />
      </Grid>
			<Grid item xs>
        <Button 
          style={{borderRadius: 25, marginRight: 20, color: "black", fontFamily: "Poppins" }}
          href="https://aisensy.com/integrations"
          target="_blank"
        >
          Integrations
        </Button>	
      </Grid>
      <Grid item>
        <Button 
          variant="outlined"
          color="primary"
          style={{borderRadius: 25}}
          href="https://app.aisensy.com/signup"
          target="_blank"
          className={classes.rightMargin}
        >
          Sign Up
        </Button>	
      </Grid>
    </Grid>
	)
}
const ActiveBtn = (props: { path: string }): React.ReactElement => {
  switch (props.path) {
    case "/generate-whatsapp-link":
      return (
        <Button 
          style={{borderRadius: 25, marginRight: 20}}
          component={Link}
          to="/generate-whatsapp-button"
        >
          WhatsApp Button
        </Button>	
      );
      case "/generate-whatsapp-button":
        return (
          <Button 
            style={{borderRadius: 25, marginRight: 20}}
            component={Link}
            to="/generate-whatsapp-link"
          >
            WhatsApp Link
          </Button>	
        );
    default: 
      return (
        <Button 
          style={{borderRadius: 25, marginRight: 20}}
          href="https://help.aisensy.com/"
          target="_blank"
        >
          Resources
        </Button>
      );
  }

}
interface Props extends WithStyles<typeof styles>, RouteComponentProps{
	// name: string,
	// number: string
}

const styles = (theme: Theme) => createStyles({
	"root": {
		height: 60,
		width: "100vw",
		position: "sticky",
		top: 0,
		left: 0,
		backgroundColor: "#EBF5F3",
		// backgroundColor: "#ffffff",
		boxShadow: "rgb(0 0 0 /10%) 0px 0px 0.428571rem 0px",
		// backgroundColor: theme.palette.primary.main,
		// paddingLeft: theme.spacing(2),
		boxSizing: "border-box",
		zIndex: 100,
		// color: "white"
		// boxShadow: "0px 0px 2px 2px lightgrey"
    
	},
	"logo": {
		height: 50,
		marginRight: 50,
    [theme.breakpoints.down('md')]: {
			marginRight: 20,
			// width: 60,
      height: 40,
			marginLeft: 10
		}
	},
  "rightMargin": {
    [theme.breakpoints.down('sm')]: {
			marginRight: 10
		}
  }
})

export default withStyles(styles)(withRouter(NavbarDesktop));